import { useState, useEffect, useRef } from 'react';
import { AiOutlineUser } from 'react-icons/ai';
import { FaPhoneVolume } from 'react-icons/fa';
import useDarkMode from '../hooks/useDarkMode';
import '../index.css';
import { toast } from 'react-toastify';

function RatingModal({ setModalOn }) {
    useDarkMode();
    const [rating, setRating] = useState(0);
    const [hover, setHover] = useState(0);
    const [phoneNumber, setPhoneNumber] = useState('');
    const [clientName, setClientName] = useState('');
    const [showAdditionalQuestions, setShowAdditionalQuestions] = useState(false);
    const [showReviewForm, setShowReviewForm] = useState(false);
    const [formErrors, setFormErrors] = useState({});
    const [showSuccess, setShowSuccess] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const SuccessIndicator = require('react-success-indicator');
    const [reviewText, setReviewText] = useState('');
    const [serviceSpeed, setServiceSpeed] = useState('');
    const [satisfaction, setSatisfaction] = useState('');
    const [shake, setShake] = useState(false); // إضافة state جديد للتحكم في التأثير
    const reviewTextRef = useRef(null);
    const phoneInputRef = useRef(null);

    // إضافة تأثير لإعادة تشغيل الحركة
    useEffect(() => {
        if (shake) {
            const timer = setTimeout(() => {
                setShake(false);
            }, 1000); // إيقاف التأثير بعد ثانية
            return () => clearTimeout(timer);
        }
    }, [shake]);

    // تجميد الاسكرول عند فتح النافذة
    useEffect(() => {
        // حفظ موضع الاسكرول الحالي
        const scrollY = window.scrollY;
        
        // تطبيق التجميد على body
        document.body.style.overflow = 'hidden';
        document.body.style.position = 'fixed';
        document.body.style.top = `-${scrollY}px`;
        document.body.style.width = '100%';
        
        return () => {
            // إزالة التجميد عند إغلاق النافذة
            document.body.style.overflow = '';
            document.body.style.position = '';
            document.body.style.top = '';
            document.body.style.width = '';
            // استعادة موضع الاسكرول
            window.scrollTo(0, scrollY);
        };
    }, []);

    const isValidPhoneNumber = (phone) => {
        return /^\d+$/.test(phone);
    };

    const handleSubmit = async () => {
        let errors = {};
        let hasError = false;

        if (!showAdditionalQuestions) {
            if (!rating) {
                errors.rating = true;
                hasError = true;
            }
            if (!clientName.trim()) {
                errors.name = true;
                hasError = true;
            }
            if (!phoneNumber.trim()) {
                errors.phone = true;
                hasError = true;
            }
            if (!isValidPhoneNumber(phoneNumber)) {
                errors.phone = true;
                hasError = true;
            }
        } else if (!showReviewForm) {
            if (!serviceSpeed) {
                errors.serviceSpeed = true;
                hasError = true;
            }
            if (!satisfaction) {
                errors.satisfaction = true;
                hasError = true;
            }
        } else {
            if (!reviewText.trim()) {
                setFormErrors(prev => ({ ...prev, reviewText: 'الرجاء إدخال ملاحظاتك' }));
                setShake(true);
                toast.error('الرجاء إدخال ملاحظاتك', {
                    toastId: 'review-error',
                });
                reviewTextRef.current?.focus();
                return;
            }

            try {
                setIsSubmitting(true);
                const requestData = {
                    clientName,
                    phoneNumber,
                    rating,
                    reviewText,
                    serviceSpeed,
                    satisfaction
                };
                
                const response = await fetch('/send_review_email.php', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json'
                    },
                    body: JSON.stringify(requestData)
                });

                const responseText = await response.text();
                let data;
                try {
                    data = JSON.parse(responseText);
                } catch (e) {
                    throw new Error('Invalid JSON response');
                }
                
                if (data.success) {
                    toast.success('تم إرسال تقييمك بنجاح', {
                        toastId: 'rating-success',
                    });
                    setShowSuccess(true);
                    setTimeout(() => {
                        setModalOn(false);
                    }, 3000);
                } else {
                    throw new Error(data.message || 'حدث خطأ أثناء إرسال التقييم');
                }
            } catch (error) {
                toast.error('حدث خطأ أثناء الإرسال، يرجى المحاولة مرة أخرى', {
                    toastId: 'rating-error',
                });
            } finally {
                setIsSubmitting(false);
            }
        }

        if (hasError) {
            setFormErrors(errors);
            setShake(true);
            if (!rating) {
                toast.error('الرجاء اختيار تقييم', {
                    toastId: 'rating-error',
                });
            }
            setTimeout(() => setShake(false), 500);
            return;
        }

        if (!showAdditionalQuestions) {
            setShowAdditionalQuestions(true);
            setFormErrors({});
        } else if (!showReviewForm) {
            setShowReviewForm(true);
            setFormErrors({});
        }
    };

    const handlePrevious = () => {
        if (showReviewForm) {
            setShowReviewForm(false);
        } else if (showAdditionalQuestions) {
            setShowAdditionalQuestions(false);
        }
    };

    const handleStarClick = (ratingValue) => {
        setRating(ratingValue);
        setFormErrors((prevErrors) => ({
            ...prevErrors,
            rating: '',
        }));
    };

    const handleInputChange = (e, field) => {
        const value = e.target.value;
        
        if (field === 'name') {
            setClientName(value);
            if (!value.trim()) {
                setFormErrors(prev => ({ ...prev, name: 'الرجاء إدخال اسم العميل' }));
            } else {
                setFormErrors(prev => ({ ...prev, name: '' }));
            }
        } else if (field === 'phone') {
            setPhoneNumber(value);
            if (!value.trim()) {
                setFormErrors(prev => ({ ...prev, phone: 'الرجاء إدخال رقم الجوال' }));
            } else {
                setFormErrors(prev => ({ ...prev, phone: '' }));
            }
        }
    };

    const handlePhoneChange = (e) => {
        const value = e.target.value;
        if (/^\d*$/.test(value) && value.length <= 10) {
            handleInputChange(e, 'phone');
        }
    };

    // التحكم في موضع المؤشر
    const handlePhoneFocus = (e) => {
        // تأخير قصير لضمان تنفيذ الكود بعد تحديث DOM
        setTimeout(() => {
            if (phoneInputRef.current) {
                const length = phoneInputRef.current.value.length;
                phoneInputRef.current.setSelectionRange(length, length);
            }
        }, 0);
    };

    useEffect(() => {
        if (showSuccess) {
            setTimeout(() => {
                setModalOn(false);
            }, 3000);
        }
    }, [showSuccess, setModalOn]);

    return (
        <div className="fixed inset-0 z-50 flex items-center justify-center p-4 sm:p-0">
            <div
                className="fixed inset-0"
                style={{
                    backdropFilter: 'blur(3px) saturate(80%)',
                    WebkitBackdropFilter: 'blur(3px) saturate(80%)'
                }}
            ></div>
            <div className={`fixed max-w-md mx-auto w-full right-0 bottom-0 dark:bg-gray-700 flex flex-col justify-end gap-3 px-4 rounded-tr-[35px] rounded-tl-[35px] z-[401] py-4 bg-white shadow-[0_1px_6px_rgba(0,0,0,0.5)] animateItems ${showReviewForm || showAdditionalQuestions || showSuccess ? 'hidden' : ''}`}>
                <div className="w-12 h-1 bg-gray-300 dark:bg-gray-600 rounded-full mx-auto -mt-2"></div>
                <div className="flex flex-col w-full gap-3">
                    <div className="mt-2 mb-4">
                        {!showSuccess ? (
                            <div className='flex flex-col items-center gap-4'>
                                <h4 className="font-semibold text-lg text-center text-primaryGreen-500 dark:text-primaryGreen-200">ما رأيك في دبليو فايف !؟</h4>
                                <div className='flex items-center justify-center gap-2'>
                                    {[1, 2, 3, 4, 5].map((index) => {
                                        const ratingValue = index;
                                        return (
                                            <label
                                                key={index}
                                                className={`cursor-pointer relative ${(formErrors?.rating && shake) ? 'animate-shake' : ''}`}
                                                style={{ animationDelay: `${index * 0.1}s` }}
                                            >
                                                <input
                                                    type='radio'
                                                    name='rating'
                                                    value={ratingValue}
                                                    onClick={() => {
                                                        handleStarClick(ratingValue);
                                                        setFormErrors(prev => ({ ...prev, rating: '' }));
                                                    }}
                                                    className='hidden'
                                                />
                                                {formErrors?.rating && (
                                                    <svg 
                                                        viewBox="0 0 51 48" 
                                                        className="absolute top-0 left-0 w-10 h-10"
                                                    >
                                                        <path
                                                            d="M25.5 3.5c0.8 0 1.6 0.4 2 1.2l5.2 10.6c0.4 0.8 1.2 1.3 2 1.4l11.7 1.7c1.8 0.3 2.5 2.4 1.2 3.7l-8.5 8.3c-0.6 0.6-0.9 1.4-0.7 2.3l2 11.7c0.3 1.8-1.6 3.1-3.2 2.3l-10.5-5.5c-0.8-0.4-1.7-0.4-2.5 0l-10.5 5.5c-1.6 0.8-3.5-0.5-3.2-2.3l2-11.7c0.1-0.8-0.1-0.7-0.7-2.3l-8.5-8.3c-1.3-1.3-0.6-3.4 1.2-3.7l11.7-1.7c0.8-0.1 1.6-0.6 2-1.4l5.2-10.6c0.4-0.8 1.2-1.2 2-1.2z"
                                                            fill="none"
                                                            stroke="#EF4444"
                                                            strokeWidth="2"
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                        />
                                                    </svg>
                                                )}
                                                {ratingValue <= (hover || rating) ? (
                                                    <svg 
                                                        viewBox="0 0 51 48" 
                                                        className="w-10 h-10"
                                                        onMouseEnter={() => setHover(ratingValue)}
                                                        onMouseLeave={() => setHover(rating)}
                                                    >
                                                        <path
                                                            d="M25.5 3.5c0.8 0 1.6 0.4 2 1.2l5.2 10.6c0.4 0.8 1.2 1.3 2 1.4l11.7 1.7c1.8 0.3 2.5 2.4 1.2 3.7l-8.5 8.3c-0.6 0.6-0.9 1.4-0.7 2.3l2 11.7c0.3 1.8-1.6 3.1-3.2 2.3l-10.5-5.5c-0.8-0.4-1.7-0.4-2.5 0l-10.5 5.5c-1.6 0.8-3.5-0.5-3.2-2.3l2-11.7c0.1-0.8-0.1-0.7-0.7-2.3l-8.5-8.3c-1.3-1.3-0.6-3.4 1.2-3.7l11.7-1.7c0.8-0.1 1.6-0.6 2-1.4l5.2-10.6c0.4-0.8 1.2-1.2 2-1.2z"
                                                            fill="#facc15"
                                                            stroke="#facc15"
                                                            strokeWidth="2"
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                        />
                                                    </svg>
                                                ) : (
                                                    <svg 
                                                        viewBox="0 0 51 48" 
                                                        className="w-10 h-10"
                                                        onMouseEnter={() => setHover(ratingValue)}
                                                        onMouseLeave={() => setHover(rating)}
                                                    >
                                                        <path
                                                            d="M25.5 3.5c0.8 0 1.6 0.4 2 1.2l5.2 10.6c0.4 0.8 1.2 1.3 2 1.4l11.7 1.7c1.8 0.3 2.5 2.4 1.2 3.7l-8.5 8.3c-0.6 0.6-0.9 1.4-0.7 2.3l2 11.7c0.3 1.8-1.6 3.1-3.2 2.3l-10.5-5.5c-0.8-0.4-1.7-0.4-2.5 0l-10.5 5.5c-1.6 0.8-3.5-0.5-3.2-2.3l2-11.7c0.1-0.8-0.1-0.7-0.7-2.3l-8.5-8.3c-1.3-1.3-0.6-3.4 1.2-3.7l11.7-1.7c0.8-0.1 1.6-0.6 2-1.4l5.2-10.6c0.4-0.8 1.2-1.2 2-1.2z"
                                                            fill="white"
                                                            stroke="#9CA3AF"
                                                            strokeWidth="2"
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                        />
                                                    </svg>
                                                )}
                                            </label>
                                        );
                                    })}
                                </div>
                            </div>
                        ) : (
                            <div className="flex flex-col items-center justify-center text-main-900 dark:text-white">
                                <div>
                                    <SuccessIndicator size='96px' color='#432918' className='dark:fill-white' />
                                </div>

                                <p className="mt-4 text-main-900 dark:text-white">
                                    تم التقييم بنجاح شكراً لك
                                </p>
                            </div>
                        )}
                    </div>

                                <label htmlFor='clientName' className='relative'>
                        <input
                            type='text'
                            id='clientName'
                            name='clientName'
                            autoComplete='off'
                            maxLength='25'
                            enterKeyHint="next"
                            value={clientName}
                            onChange={(e) => handleInputChange(e, 'name')}
                            className={`peer h-12 w-full pr-10 p-3 border-2 rounded-xl bg-transparent outline-none transition-all duration-300 placeholder:text-gray-400 placeholder:opacity-0 focus:placeholder:opacity-100 ${
                                (formErrors?.name && shake) ? 'animate-shake border-2 border-red-500 text-red-500' : 'border-gray-300 focus:border-main-900 text-gray-900 dark:text-white dark:border-gray-600'
                            }`}
                            placeholder='مثال : محمد'
                        />
                        <AiOutlineUser className={`absolute right-3 top-1/2 -translate-y-1/2 text-lg ${
                            (formErrors?.name && shake) ? 'text-red-500' : 'text-gray-400 peer-focus:text-main-900'
                        } z-20`} />
                        <span className={`absolute text-base leading-none duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-700 px-2 peer-focus:px-2 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:text-base peer-focus:leading-none peer-focus:-translate-y-4 right-10 peer-placeholder-shown:bg-transparent peer-focus:bg-white dark:peer-focus:bg-gray-700 ${
                            (formErrors?.name && shake) ? 'text-red-500' : 'text-gray-400 peer-focus:text-main-900'
                        }`}>
                            اسم العميل
                        </span>
                    </label>

                                <label htmlFor='phoneNumber' className='relative'>
                        <input
                            ref={phoneInputRef}
                            type='tel'
                            id='phoneNumber'
                            name='phoneNumber'
                            autoComplete='off'
                            maxLength='10'
                            enterKeyHint="done"
                            dir="rtl"
                            style={{ textAlign: 'right', direction: 'rtl' }}
                            value={phoneNumber}
                            onChange={handlePhoneChange}
                            onFocus={handlePhoneFocus}
                            className={`peer w-full h-12 pr-10 p-3 border-2 rounded-xl bg-transparent outline-none transition-all duration-300 placeholder:text-gray-400 placeholder:opacity-0 focus:placeholder:opacity-100 ${
                                (formErrors?.phone && shake) ? 'animate-shake border-2 border-red-500 text-red-500' : 'border-gray-300 focus:border-main-900 text-gray-900 dark:text-white dark:border-gray-600'
                            }`}
                            placeholder='مثال : 0555555555'
                        />
                        <FaPhoneVolume className={`absolute right-3 top-1/2 -translate-y-1/2 text-lg ${
                            (formErrors?.phone && shake) ? 'text-red-500' : 'text-gray-400 peer-focus:text-main-900'
                        } z-20`} />
                        <span className={`absolute text-base leading-none duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-700 px-2 peer-focus:px-2 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:text-base peer-focus:leading-none peer-focus:-translate-y-4 right-10 peer-placeholder-shown:bg-transparent peer-focus:bg-white dark:peer-focus:bg-gray-700 ${
                            (formErrors?.phone && shake) ? 'text-red-500' : 'text-gray-400 peer-focus:text-main-900'
                        }`}>
                            رقم الجوال
                        </span>
                    </label>
                </div>

                <div className="flex gap-2">
                    <button
                        type='button'
                        onClick={() => setModalOn(false)}
                        className="bg-gray-300 text-gray-800 py-2 w-1/2 rounded-full dark:bg-gray-900 dark:text-white dark:border-[#111827]"
                    >
                        إغلاق
                    </button>
                    <button
                        type='button'
                        onClick={handleSubmit}
                        className={`bg-main-900 text-white py-2 w-1/2 rounded-full ${!phoneNumber || !rating || !clientName ? 'opacity-50 cursor-not-allowed' : ''}`}
                    >
                        التالي
                    </button>
                </div>
            </div>

            {showAdditionalQuestions && !showReviewForm && !showSuccess && (
                <div className={`fixed max-w-md mx-auto w-full right-0 bottom-0 dark:bg-gray-700 flex flex-col justify-end gap-3 px-4 rounded-tr-[35px] rounded-tl-[35px] z-[401] py-4 bg-white shadow-[0_1px_6px_rgba(0,0,0,0.5)] animateItems`}>
                    <div className="w-12 h-1 bg-gray-300 dark:bg-gray-600 rounded-full mx-auto -mt-2"></div>
                    <div className="space-y-4 mt-6">
                        <div className="relative">
                            <select
                                name="serviceSpeed"
                                value={serviceSpeed}
                                onChange={(e) => {
                                    setServiceSpeed(e.target.value);
                                    const label = document.querySelector('label[for="serviceSpeed"]');
                                    if (label) {
                                        if (e.target.value === '') {
                                            label.classList.remove('focused');
                                        } else {
                                            label.classList.add('focused');
                                        }
                                    }
                                }}
                                onFocus={() => {
                                    const label = document.querySelector('label[for="serviceSpeed"]');
                                    if (label && !serviceSpeed) {
                                        label.classList.add('focused');
                                    }
                                }}
                                onBlur={() => {
                                    const label = document.querySelector('label[for="serviceSpeed"]');
                                    if (label && !serviceSpeed) {
                                        label.classList.remove('focused');
                                    }
                                }}
                                className={`peer w-full h-12 pr-10 pl-8 border-2 rounded-xl bg-transparent outline-none transition-all duration-300 text-base text-right appearance-none ${
                                    (formErrors?.serviceSpeed && shake) ? 'animate-shake border-2 border-red-500 text-red-500' : 'border-gray-300 focus:border-main-900 text-gray-900 dark:text-white dark:border-gray-600'
                                }`}
                                style={{
                                    backgroundImage: `url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='%236b7280'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M19 9l-7 7-7-7'%3E%3C/path%3E%3C/svg%3E")`,
                                    backgroundPosition: 'left 0.75rem center',
                                    backgroundSize: '1.5em 1.5em',
                                    backgroundRepeat: 'no-repeat'
                                }}
                            >
                                <option value=""></option>
                                <option value="ممتازة">ممتازة</option>
                                <option value="جيدة">جيدة</option>
                                <option value="متوسطة">متوسطة</option>
                                <option value="ضعيفة">ضعيفة</option>
                            </select>
                            <label 
                                htmlFor="serviceSpeed"
                                onClick={() => {
                                    const select = document.querySelector('select[name="serviceSpeed"]');
                                    if (select) {
                                        select.focus();
                                        select.click();
                                    }
                                }}
                                className={`absolute text-base leading-none duration-300 transform z-10 origin-[0] bg-white dark:bg-gray-700 px-2 peer-focus:px-2 peer-placeholder-shown:scale-100 peer-focus:text-base peer-focus:leading-none peer-focus:-translate-y-4 peer-focus:-translate-x-4 peer-focus:top-2 peer-focus:scale-75 right-8 
                                ${(serviceSpeed && serviceSpeed !== '') || document.querySelector('label[for="serviceSpeed"].focused') ? 'text-base leading-none -translate-y-4 -translate-x-4 top-2 scale-75' : 'top-1/2 -translate-y-1/2'} 
                                ${(formErrors?.serviceSpeed && shake) ? 'text-red-500' : 'text-gray-400 peer-focus:text-main-900'}`}
                            >
                                سرعة الخدمة
                            </label>
                            <span className={`absolute inset-y-0 right-3 flex items-center ${
                                (formErrors?.serviceSpeed && shake) ? 'text-red-500' : 'text-gray-400 peer-focus:text-main-900'
                            } z-20`}>
                               <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor"><path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-12a1 1 0 10-2 0v4a1 1 0 00.293.707l2.828 2.829a1 1 0 101.415-1.415L11 9.586V6z" clip-rule="evenodd"></path></svg>
                            </span>
                        </div>

                        <div className="relative">
                            <select
                                name="satisfaction"
                                value={satisfaction}
                                onChange={(e) => {
                                    setSatisfaction(e.target.value);
                                    const label = document.querySelector('label[for="satisfaction"]');
                                    if (label) {
                                        if (e.target.value === '') {
                                            label.classList.remove('focused');
                                        } else {
                                            label.classList.add('focused');
                                        }
                                    }
                                }}
                                onFocus={() => {
                                    const label = document.querySelector('label[for="satisfaction"]');
                                    if (label && !satisfaction) {
                                        label.classList.add('focused');
                                    }
                                }}
                                onBlur={() => {
                                    const label = document.querySelector('label[for="satisfaction"]');
                                    if (label && !satisfaction) {
                                        label.classList.remove('focused');
                                    }
                                }}
                                className={`peer w-full h-12 pr-10 pl-8 border-2 rounded-xl bg-transparent outline-none transition-all duration-300 text-base text-right appearance-none ${
                                    (formErrors?.satisfaction && shake) ? 'animate-shake border-2 border-red-500 text-red-500' : 'border-gray-300 focus:border-main-900 text-gray-900 dark:text-white dark:border-gray-600'
                                }`}
                                style={{
                                    backgroundImage: `url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='%236b7280'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M19 9l-7 7-7-7'%3E%3C/path%3E%3C/svg%3E")`,
                                    backgroundPosition: 'left 0.75rem center',
                                    backgroundSize: '1.5em 1.5em',
                                    backgroundRepeat: 'no-repeat'
                                }}
                            >
                                <option value=""></option>
                                <option value="راضٍ جداً">راضٍ جداً</option>
                                <option value="راضٍ">راضٍ</option>
                                <option value="محايد">محايد</option>
                                <option value="غير راضٍ">غير راضٍ</option>
                            </select>
                            <label 
                                htmlFor="satisfaction"
                                onClick={() => {
                                    const select = document.querySelector('select[name="satisfaction"]');
                                    if (select) {
                                        select.focus();
                                        select.click();
                                    }
                                }}
                                className={`absolute text-base leading-none duration-300 transform z-10 origin-[0] bg-white dark:bg-gray-700 px-2 peer-focus:px-2 peer-placeholder-shown:scale-100 peer-focus:text-base peer-focus:leading-none peer-focus:-translate-y-4 peer-focus:-translate-x-4 peer-focus:top-2 peer-focus:scale-75 right-8 
                                ${(satisfaction && satisfaction !== '') || document.querySelector('label[for="satisfaction"].focused') ? 'text-base leading-none -translate-y-4 -translate-x-4 top-2 scale-75' : 'top-1/2 -translate-y-1/2'} 
                                ${(formErrors?.satisfaction && shake) ? 'text-red-500' : 'text-gray-400 peer-focus:text-main-900'}`}
                            >
                                مستوى الرضا
                            </label>
                            <span className={`absolute inset-y-0 right-3 flex items-center ${
                                (formErrors?.satisfaction && shake) ? 'text-red-500' : 'text-gray-400 peer-focus:text-main-900'
                            } z-20`}>
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                                <circle cx="10" cy="10" r="8" fill="currentColor" />
                                <circle cx="7" cy="8" r="1.5" fill="white" />
                                <circle cx="13" cy="8" r="1.5" fill="white" />
                                <path d="M7 13c1 1 3 1 6 0" stroke="white" strokeWidth="1.5" fill="none" strokeLinecap="round" />
                            </svg>
                            </span>
                        </div>
                    </div>
                    <div className='flex justify-between gap-2'>
                        <button
                            type='button'
                            className='bg-gray-300 text-gray-800 py-2 rounded-full flex-1 dark:bg-gray-900 dark:text-white dark:border-[#111827]'
                            onClick={handlePrevious}
                            disabled={isSubmitting}>
                            السابق
                        </button>
                        <button
                            type='button'
                            onClick={handleSubmit}
                            disabled={isSubmitting}
                            className={`bg-main-900 text-white py-2 rounded-full w-1/2 ${!serviceSpeed || !satisfaction ? 'opacity-50 cursor-not-allowed' : ''}`}>
                            التالي
                        </button>
                    </div>
                </div>
            )}

            {showReviewForm && !showSuccess && (
                <div className={`fixed max-w-md mx-auto w-full right-0 bottom-0 dark:bg-gray-700 flex flex-col justify-end gap-3 px-4 rounded-tr-[35px] rounded-tl-[35px] z-[401] py-4 bg-white shadow-[0_1px_6px_rgba(0,0,0,0.5)] animateItems`}>
                    <div className="w-12 h-1 bg-gray-300 dark:bg-gray-600 rounded-full mx-auto -mt-2"></div>
                    <div className="space-y-4 mt-6">
                        <div className="relative">
                            <textarea
                                ref={reviewTextRef}
                                id="reviewText"
                                name="reviewText"
                                value={reviewText}
                                onChange={(e) => setReviewText(e.target.value)}
                                placeholder='أخبرنا أكثر عن تجربتك ...'
                                className={`peer w-full h-32 p-4 border-2 rounded-xl bg-transparent outline-none transition-all duration-300 resize-none text-right ${
                                    (formErrors?.reviewText && shake) ? 'animate-shake border-2 border-red-500 text-red-500' : 'border-gray-300 focus:border-main-900 text-gray-900 dark:text-white dark:border-gray-600'
                                }`}
                            />
                            <label
                                htmlFor="reviewText"
                                className={`absolute text-base leading-none duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-700 px-2 peer-focus:px-2 peer-placeholder-shown:text-base peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-2 peer-focus:-translate-y-4 peer-focus:scale-75 peer-focus:text-base peer-focus:leading-none right-4 ${
                                    (formErrors?.reviewText && shake) ? 'text-red-500' : 'text-gray-400 peer-focus:text-main-900'
                                }`}
                            >
                                ملاحظاتك وتقييمك
                            </label>
                        </div>
                    </div>
                    <div className='flex justify-between gap-2'>
                        <button
                            type='button'
                            className='bg-gray-300 text-gray-800 py-2 rounded-full flex-1 dark:bg-gray-900 dark:text-white dark:border-[#111827]'
                            onClick={handlePrevious}
                            disabled={isSubmitting}>
                            السابق
                        </button>
                        <button
                            type='button'
                            onClick={handleSubmit}
                            disabled={isSubmitting}
                            className={`bg-main-900 text-white py-2 rounded-full flex-1 ${!reviewText.trim() || isSubmitting ? 'opacity-50 cursor-not-allowed' : ''}`}>
                            {isSubmitting ? 'جاري الإرسال...' : 'إرسال'}
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
}

export default RatingModal;
