const Item = ({ item, setModalOn, modalOn, singleItem, setSingleItem }) => {
  // تعطيل فتح صفحة الصنف
  const handleClick = () => {
    return; // لا تفعل شيئاً عند الضغط
  }

  return (
    <div
      onClick={!item.available ? undefined : handleClick}
      className={`w-full h-32 rounded-lg grid grid-cols-12 gap-2 cursor-pointer bg-gray-100 dark:bg-gray-900 ${
        !item.available ? 'cursor-not-allowed select-none' : 'cursor-pointer'
      }`}
    >
      {!item.available ? (
        <div className='absolute inset-0 w-full h-full z-[50] text-center flex flex-col items-center justify-center backdrop-blur-[2px] bg-[#597c80]/40 dark:bg-gray-800/60 font-bold rounded-lg select-none'>
          <div className="bg-red-500/80 dark:bg-red-600/80 px-6 py-2 rounded-full transform -rotate-12 shadow-lg">
            <span className="text-white text-xl">غير متوفر</span>
          </div>
        </div>
      ) : null}
      <div className='relative w-full rounded-lg col-span-4 sm:col-span-3 flex items-center justify-center'>
        <img
          className='absolute inset-0 w-full h-full p-0.5 object-cover rounded-lg'
          src={item.image}
          alt='item img'
        />
      </div>
      <div className='w-full relative col-span-8 sm:col-span-9 space-y-1 sm:space-y-2 px-2 flex flex-col justify-between'>
        <h1 className='mt-2 text-sm text-primaryGreen-500 font-semibold  dark:text-white'>
          {item.title}
        </h1>
        <p className='text-xs text-gray-800 dark:text-gray-400 overflow-hidden'>
          {item.description}
        </p>
        <div className='flex justify-between py-2 items-center w-full'>
          {item.calories ? (
            <span className='text-xs text-gray-800 dark:text-gray-400 '>
              <span className='text-xs mx-0.5'>سعرات حرارية :</span>
              {item.calories}
            </span>
          ) : (
            ''
          )}

          <span className={`text-sm flex items-center font-semibold self-end mr-auto dark:text-white relative ${!item.available && 'after:content-[""] after:absolute after:w-full after:h-[2px] after:bg-red-500 after:left-0 after:top-1/2 after:-rotate-12'}`}>
            {item.price.toLocaleString('en-US')}
            <span className='text-primaryGreen-500 dark:text-white text-xs font-semibold mx-0.5'>
              ريال
            </span>
          </span>
        </div>
      </div>
    </div>
  )
}

export default Item
