import logo_light from "../img/logo.png"
import logo_dark from "../img/logo2.png"
import { Link } from "react-router-dom"

export default function TopNav({ setSideNav }) {
  return (
    <header className="z-[60] rounded-bb-xl transition duration-200 dark:bg-opacity-300 relative bg-white dark:bg-gray-800">
      <nav className="relative App-header absolutee left-0 right-0 text-white rounded-bb-xl">
        <div className="w-full max-w-md mx-auto h-16 top-auto rounded-t-4xl py-1 flex items-center justify-between gap-2 rounded-bb-xl dark:bg-gray-700 bg-opacity-50">
          <div className="col-span-9 grid justify-start items-center">
            <Link
              to="/"
              className="col-span-10 pr-4 text-md font-semibold text-gray-500 dark:text-white overflow-y-hidden flex items-center cursor-pointer gap-2"
            >
              <img
                src={logo_light}
                alt="main logo"
                className=" w-[56px] h-[57px] dark:hidden"
              />
              <img
                src={logo_dark}
                alt="main logo"
                className=" w-[56px] h-[57px] hidden dark:block"
              />
              <h1 className="font-extrabold flex gap-1 flex-col text-md text-primaryGreen-500 dark:text-primaryGreen-200">
                <span className="inline-block transform translate-y-1 text-brown-400">
                دبليو فايف
                </span>
                <span className="font-extrabold text-md text-primaryGreen-500 dark:text-primaryGreen-200 overflow-hidden">
                  {" "}
                  W5 Lounge
                </span>
              </h1>
            </Link>
          </div>
          <div className="col-span-3 flex justify-end ml-4 items-center">
            <div
              className="col-span-2 w-10 h-10 p-2 mxx-2 rounded-full text-black transition duration-200 dark:text-gray-100 hover:bg-gray-400 hover:bg-opacity-50 flex justify-center items-center cursor-pointer"
              onClick={() => setSideNav(true)}
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill=""
                className="text-primaryGreen-500 dark:text-white"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  y="2"
                  width="20"
                  height="2.5"
                  rx="1.5"
                  fill="currentColor"
                />
                <rect
                  y="18"
                  width="20"
                  height="2.5"
                  rx="1.5"
                  fill="currentColor"
                />
                <rect
                  x="4"
                  y="10"
                  width="20"
                  height="2.5"
                  rx="1.5"
                  fill="currentColor"
                />
              </svg>
            </div>
          </div>
        </div>
      </nav>
    </header>
  )
}
