import { useState, useEffect } from 'react';
import { FiX, FiSun, FiMoon } from 'react-icons/fi';
import { IoShareOutline, IoLocationOutline } from 'react-icons/io5';
import { BiCategory } from "react-icons/bi";
import { TbMessage2Star } from "react-icons/tb";
import { CategoriesSheet } from './BottomSheet';
import RatingModal from './RatingModal';
import Modal from './Modal';
import { data, sideNavData } from '../data';
import { useLockBodyScroll } from '@uidotdev/usehooks';
import useDarkMode from '../hooks/useDarkMode';

const SideNav = ({ sideNav, setSideNav, swiper }) => {
  const [isDarkMode, toggleDarkMode] = useDarkMode();
  const [modal1On, setModal1On] = useState(false);
  const [modal2On, setModal2On] = useState(false);
  const [modal3On, setModal3On] = useState(false);
  useLockBodyScroll();

  useEffect(() => {
    if (sideNav) {
      // Reset animation state
      // Trigger animation after a delay to ensure complete reset
      const timer = setTimeout(() => {
      }, 300);
      return () => clearTimeout(timer);
    } else {
    }
  }, [sideNav]);

  const share = (event) => {
    if (navigator.share) {
      navigator
        .share({
          text: 'دبليو فايف : W5 Lounge',
          url: 'https://w5-lounge.online/',
        })
        .then(() => {
          console.log('! شكراً على المشاركة');
        })
        .catch((err) => console.error(err));
    } else {
      alert('جهازك الحالي لا يدعم خاصية المشاركة');
    }
  };

  const toggleTheme = () => {
    return (
      <div
        onClick={() => {
          setSideNav(false);
          toggleDarkMode();
        }}
        className="relative flex justify-between items-center py-2.5 px-4 rounded-br-md rounded-bl-md transition bg-gray-100 bg-opacity-500 text-gray-800 cursor-pointer"
      >
        <h1 className="text-xs md:text-md text-gray-900 font-semibold">
          {isDarkMode ? 'الوضع النهاري' : 'الوضع الليلي'}
        </h1>
        <div
          className={`flex items-center p-1 rounded-full ${
            isDarkMode ? 'bg-white text-gray-800' : 'bg-gray-700 text-gray-100'
          } transition-all duration-300 hover:scale-110`}
        >
          {isDarkMode ? (
            <FiSun className="w-5 h-5 transition-transform duration-300 hover:rotate-45" />
          ) : (
            <FiMoon className="w-5 h-5 transition-transform duration-300 hover:-rotate-12" />
          )}
        </div>
      </div>
    );
  };

  return (
    <div className='relative inset-0 flex justify-center z-[102]'>
      <div
        className={`fixed inset-0 bg-black dark:bg-white bg-opacity-70 dark:bg-opacity-70 ${sideNav ? 'block' : 'hidden'
          }`}
        onClick={() => setSideNav(false)}
      ></div>
      <div
        className={`fixed w-[350px] md:w-[447px] top-0 right-0 bg-white flex flex-col dark:bg-gray-700 h-full transition-all duration-500 ease-out rounded-sid  ${
          sideNav ? 'left-[calc(100%-350px)] md:left-[calc(100%-447px)]' : 'left-[100%]'
        }`}
      >
        <style>
          {`
            @keyframes slideUp {
              0% {
                opacity: 0;
                transform: translateY(20px);
              }
              100% {
                opacity: 1;
                transform: translateY(0);
              }
            }
            @keyframes logoAnimation {
              0% {
                opacity: 0;
                transform: scale(0.5) rotate(-20deg);
              }
              100% {
                opacity: 1;
                transform: scale(1) rotate(0);
              }
            }
            .animate-slide-up {
              opacity: 0;
              transform: translateY(20px);
              will-change: transform, opacity;
            }
            .animate-slide-up.active {
              animation: slideUp 0.5s ease forwards;
            }
          `}
        </style>
        <FiX
          className={`eax absolute left-1.5 top-1.5 z-103 w-5 h-5 p-0.5 mx-2 rounded-full bg-primaryGreen-500 text-gray-50 hover:bg-opacity-50 dark:hover:bg-opacity-50 transform hover:rotate-180 dark:bg-gray-700 dark:text-gray-50 ${sideNav ? 'block' : 'hidden'
            }`}
          onClick={() => setSideNav(false)}
        />
        <div
          className="bg-primaryGreen-300 w-full dark:bg-white flex items-center justify-center py-2"
        >
          <img
            src='../../img/logo.png'
            alt='side-logo'
            className="w-[100px] h-[100px]"
            style={{
              animation: sideNav ? 'logoAnimation 0.8s cubic-bezier(0.34, 1.56, 0.64, 1) forwards' : 'none',
              opacity: sideNav ? 1 : 0,
              transitionDelay: '100ms'
            }}
          />
        </div>

        <div className={`flex flex-col w-full overflow-hidden px-2 mt-2 gap-0.5`}>
          <p className="py-2.5 px-4 rounded-tr-md rounded-tl-md bg-gray-100 bg-opacity-500 text-justify text-xs leading-normal text-gray-900 font-semibold cursor-pointer">
            يحتاج البالغون إلى 2000 سعره حرارية في المتوسط يومياً، وقد تختلف
            الاحتياجات الفردية من السعرات الحرارية من شخص لآخر البيانات التغذوية
            الإضافية متاحة عند الطلب .
          </p>

          {sideNavData.map((link, index) => (
            <a
              href={link.href}
              className={`relative flex justify-between items-center py-2.5 px-4 rounded-sm transition bg-gray-100 bg-opacity-500 text-gray-800 animate-slide-up ${sideNav ? 'active' : ''}`}
              key={link.id}
              onClick={() => setSideNav(false)}
              style={{
                animationDelay: `${index * 150}ms`
              }}
            >
              <h1 className="text-xs md:text-md text-gray-900 font-semibold">
                {link.text}
              </h1>
              <link.icon className="text-primaryGreen-500 w-5 h-5" />
            </a>
          ))}

          <button
            onClick={(e) => share(e)}
            className={`relative flex justify-between items-center py-2.5 px-4 rounded-sm transition bg-gray-100 bg-opacity-500 text-gray-800 animate-slide-up ${sideNav ? 'active' : ''}`}
            style={{
              animationDelay: `${sideNavData.length * 150}ms`
            }}
          >
            <h1 className="text-xs md:text-md text-gray-900 font-semibold">مشاركة</h1>
            <IoShareOutline className="text-primaryGreen-500 w-5 h-5" />
          </button>

          <button
            onClick={() => {
              setSideNav(false);
              setModal1On(true);
            }}
            className={`relative flex justify-between items-center py-2.5 px-4 rounded-sm transition bg-gray-100 bg-opacity-500 text-gray-800 animate-slide-up ${sideNav ? 'active' : ''}`}
            style={{
              animationDelay: `${(sideNavData.length + 1) * 150}ms`
            }}
          >
            <h1 className="text-xs md:text-md text-gray-900 font-semibold">اللوكيشن</h1>
            <IoLocationOutline className="text-primaryGreen-500 w-5 h-5" />
          </button>

          <button
            onClick={() => {
              setSideNav(false);
              setModal2On(true);
            }}
            className={`relative flex justify-between items-center py-2.5 px-4 rounded-sm transition bg-gray-100 bg-opacity-500 text-gray-800 animate-slide-up ${sideNav ? 'active' : ''}`}
            style={{
              animationDelay: `${(sideNavData.length + 2) * 150}ms`
            }}
          >
            <h1 className="text-xs md:text-md text-gray-900 font-semibold">التصنيفات</h1>
            <BiCategory className="text-primaryGreen-500 w-5 h-5" />
          </button>

          <button
            onClick={() => {
              setSideNav(false);
              setModal3On(true);
            }}
            className={`relative flex justify-between items-center py-2.5 px-4 rounded-sm transition bg-gray-100 bg-opacity-500 text-gray-800 animate-slide-up ${sideNav ? 'active' : ''}`}
            style={{
              animationDelay: `${(sideNavData.length + 3) * 150}ms`
            }}
          >
            <h1 className="text-xs md:text-md text-gray-900 font-semibold">شارك برأيك</h1>
            <TbMessage2Star className="text-primaryGreen-500 w-5 h-5" />
          </button>

          <div
            className={`animate-slide-up ${sideNav ? 'active' : ''}`}
            style={{
              animationDelay: `${(sideNavData.length + 4) * 150}ms`
            }}
          >
            {toggleTheme()}
          </div>

          {modal1On && (
            <Modal title="خريطة جوجل" setModalOn={setModal1On}>
              <iframe title="العنوان"
                src="https://maps.google.com/maps?width=600&amp;height=600&amp;hl=en&amp;q=W5 LOUNGE مطعم وكافيه W5&amp;t=&amp;z=19&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
                className="w-full h-[400px] my-4 shadow-md"
                allowFullScreen=""
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
              ></iframe>
              <a
                href="https://maps.app.goo.gl/C2T8pUECoME5akgQA"
                className="py-2 px-10 rounded-full bg-primaryGreen-500 text-white outline-none w-fit mx-auto"
                target="_blank"
                rel="noopener noreferrer"
              >
                إذهب الى الخريطة
              </a>
            </Modal>
          )}

          {modal2On && (
            <CategoriesSheet
              setModalOn={setModal2On}
              data={data}
              onSelectCategory={(id) => swiper.slideTo(id)}
            />
          )}

          {modal3On && <RatingModal setModalOn={setModal3On} />}
        </div>
      </div>
    </div>
  );
}

export default SideNav;
