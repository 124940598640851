
export const siteSettings = {
    name: "W5 Lounge",
    shortName: "W5",
    description: "W5 Lounge",
    themeColor: {
        light: "#ffffff",
        dark: "#374151"
    },
    backgroundColor: "#ffffff",
    display: "standalone",
    scope: "/",
    startUrl: "/",
    categories: ["food", "restaurant"],
    orientation: "portrait",
    language: "ar",
    direction: "rtl"
};

// إعدادات التثبيت
export const installSettings = {
    enabled: false, // تفعيل أو تعطيل نافذة التثبيت
    title: "تثبيت W5 Lounge",
    description: "قم بتثبيت W5 Lounge للوصول السريع والاستخدام بدون إنترنت",
    safari: {
        title: "اتبع الخطوات التالية : ",
        steps: [
            "اضغط على زر المشاركة",
            "اختر إضافة إلى الصفحة الرئيسية",
            <>اضغط على <span className="font-bold text-[#007AFF]">إضافة</span></>
        ]
    },
    chrome: {
        title: "تثبيت W5 Lounge على جهازك",
        description: "قم بتثبيت W5 Lounge للوصول السريع والاستخدام بدون إنترنت"
    },
    buttons: {
        install: "تثبيت",
        later: "لاحقاً",
        understood: "فهمت"
    }
};
