import { IoLogoInstagram, IoLogoWhatsapp, IoCallOutline } from 'react-icons/io5';
import { BsSnapchat } from "react-icons/bs";
import { TbBrandTiktok } from "react-icons/tb";




export const sideNavData = [
  {
    id: 1,
    text: 'سناب شات',
    icon: BsSnapchat,
    href: 'https://www.snapchat.com/add/w5official',
  },
  {
    id: 2,
    text: 'انستجرام',
    icon: IoLogoInstagram,
    href: 'https://www.instagram.com/w5loungeofficial/',
  },
  {
    id: 3,
    text: 'تيك توك',
    icon: TbBrandTiktok,
    href: 'https://www.tiktok.com/@restaurantcoffee',
  },
  {
    id: 4,
    text: 'واتساب',
    icon: IoLogoWhatsapp,
    href: 'https://api.whatsapp.com/send/?phone=966560799414',
  },
  {
    id: 5,
    text: 'أتصل بنا',
    icon: IoCallOutline,
    href: 'tel:0571562205',
  },
]

export const data = [
    {
      id: 1,
      title: 'الافطار - Breakfast',
      image: '../../../img/list/breakfast.png',
      selected: true,
      items: [
          {
              id: 1,
              title: 'كرواسون',
              description: 'Croissant',
              image: '../../img/food/img-1.jpg',
              price: "7.10",
              calories: 0,
              available: true,
              selected: false,
          },
          {
              id: 2,
              title: 'أومليت بالجبن',
              description: 'Cheese Omellete - اختار على كيفك يقدم مع الخبز والخضروات الطازجة',
              image: '../../img/food/img-2.jpg',
              price: "17.40",
              calories: 0,
              available: true,
              selected: false,
          },
          {
              id: 3,
              title: 'أفطار كونتينينتال',
              description: 'Continental Breakfast - مكون من زبدة - مربى - انجلش كيك - كرواسون - عصير برتقال - خبز التوست - مع اختياركم من القهوة',
              image: '../../img/food/img-3.jpg',
              price: "44",
              calories: 0,
              available: true,
              selected: false,
          },
          {
              id: 4,
              title: 'أومليت بالخضار',
              description: 'Spanish Omellete - اختار على كيفك يقدم مع الخبز والخضروات الطازجة',
              image: '../../img/food/img-4.jpg',
              price: "19.10",
              calories: 0,
              available: true,
              selected: false,
          },
          {
              id: 5,
              title: 'بيض عيون',
              description: 'Sunny Side Up - يقدم مع الخبز والخضروات الطازجة',
              image: '../../img/food/img-5.jpg',
              price: "15.50",
              calories: 0,
              available: true,
              selected: false,
          },
          {
              id: 6,
              title: 'بان كيك',
              description: 'Mini Pancake',
              image: '../../img/food/img-6.jpg',
              price: "23.10",
              calories: 0,
              available: true,
              selected: false,
          },
          {
              id: 7,
              title: 'كبده غنم فريش',
              description: 'Lamb Liver (Fresh) - مع الخضروات والبهارات الخاصة بنا ويقدم مع الخبز والخضروات الطازجة',
              image: '../../img/food/img-8.jpg',
              price: "40.10",
              calories: 0,
              available: true,
              selected: false,
          },
          {
              id: 8,
              title: 'كلوب ساندويش',
              description: 'Club Sandwich - خبز التوست الابيض او البر محشي بالمورتديلا الدجاج او اللحم - البيض المقلي - الخس - الطماطم مع الجبن',
              image: '../../img/food/img-9.jpg',
              price: "28.10",
              calories: 0,
              available: true,
              selected: false,
          },
          {
              id: 9,
              title: 'التوست الفرنسي',
              description: 'French Toast - قطع التوست الفرنسي الطازج مع صوص اللفندر-الكراميل- الفستق- الشوكولاتة',
              image: '../../img/food/img-10.jpg',
              price: "29.10",
              calories: 0,
              available: true,
              selected: false,
          },
          {
              id: 10,
              title: 'كريب',
              description: 'Crepe - يقدم مع أختياركم من النكهات لافندر- نوتيلا- فراولة',
              image: '../../img/food/img-11.jpg',
              price: "27.10",
              calories: 0,
              available: true,
              selected: false,
          },
      ]
  },
  {
      id: 2,
      title: 'المقبلات - Appetizers',
      image: '../../../img/list/goong.png',
      selected: false,
      items: [
          {
              id: 1,
              title: 'اجنحه الدجاج بدون عظم',
              description: 'Boneless chicken wings - يقدم مع اختياركم من الصوص الحار او صوص البافالو او صوص الباربكيو',
              image: '../../img/food/img(1).jpg',
              price: "37.10",
              calories: 0,
              available: true,
              selected: false,
          },
          {
              id: 2,
              title: 'اجنحه الدجاج بالعظم',
              description: 'Chicken wings - تقدم مع اختياركم من صوص البافالو او صوص الديناميت',
              image: '../../img/food/img-2(1).jpg',
              price: "37.10",
              calories: 0,
              available: true,
              selected: false,
          },
          {
              id: 3,
              title: 'بطاطس مقليه بالجبن',
              description: 'French fries with cheese',
              image: '../../img/food/img-3(1).jpg',
              price: "28.10",
              calories: 0,
              available: true,
              selected: false,
          },
          {
              id: 4,
              title: 'تشيلي كادبوي',
              description: 'Chili Cadboy -بطاطس مقليه مغطاه باللحم المتبل الحار والجبنه الذائبة - صوص الرانش - هالبينو',
              image: '../../img/food/img-4(1).jpg',
              price: "33.10",
              calories: 0,
              available: true,
              selected: false,
          },
          {
              id: 5,
              title: 'دجاج لولي بوب',
              description: 'Chicken lollipop - اجنحة الدجاج المتبله تقدم مع اختياركم من صوص البافالو -  صوص الديناميت',
              image: '../../img/food/img-5(1).jpg',
              price: "30.10",
              calories: 0,
              available: true,
              selected: false,
          },
          {
              id: 6,
              title: 'اصابع الموتزاريلا المقليه',
              description: 'Fried mozzarella fingers - تقدم مع صوص المارينا',
              image: '../../img/food/img-6(1).jpg',
              price: "30.10",
              calories: 0,
              available: true,
              selected: false,
          },
          {
              id: 7,
              title: ' شريمب بافالو دبليو فايف',
              description: 'Shrimp buffalo w5',
              image: '../../img/food/ac7.jpg',
              price: "49.10",
              calories: 0,
              available: true,
              selected: false,
          },
          {
              id: 8,
              title: 'دايناميت شريمب',
              description: 'Dynamite Shrimp',
              image: '../../img/food/5.jpg',
              price: "49.10",
              calories: 0,
              available: true,
              selected: false,
          },
          {
              id: 9,
              title: 'بطاطس مقليه',
              description: 'French fries',
              image: '../../img/food/img-3(1).jpg',
              price: "15.10",
              calories: 0,
              available: true,
              selected: false,
          },
      ]
  },
  {
      id: 3,
      title: 'شوربة - Soup',
      image: '../../../img/list/soop.png',
      selected: false,
      items: [
          {
              id: 1,
              title: 'شوربه توم يوم',
              description: 'Tom yum soup - مزيج من الروبيان والكالماري -  مع بهاراتنا الخاصة - وكريمة جوز الهند',
              image: '../../img/food/img-1(1).jpg',
              price: "19.10",
              calories: 0,
              available: true,
              selected: false,
          },
          {
              id: 2,
              title: 'شوربه دجاج',
              description: 'chicken soup',
              image: '../../img/food/img-2(2).jpg',
              price: "17.10",
              calories: 0,
              available: true,
              selected: false,
          },
          {
              id: 3,
              title: 'شوربه البروكلي',
              description: 'Broccoli soup - بروكلي - خضروات - كريمة',
              image: '../../img/food/img-3(2).jpg',
              price: "17.10",
              calories: 0,
              available: true,
              selected: false,
          },
          {
              id: 4,
              title: 'شوربه الدجاج والفطر',
              description: 'Chicken and mushroom soup - قطع صدور الجاج - الفطر - الكريمه',
              image: '../../img/food/img-4(2).jpg',
              price: "17.10",
              calories: 0,
              available: true,
              selected: false,
          },
          {
              id: 5,
              title: 'شوربه العدس',
              description: 'Lentil Soup - عدس والخضروات - الكريمة',
              image: '../../img/food/84.jpg',
              price: "15.10",
              calories: 0,
              available: true,
              selected: false,
          },
      ]
  },
  {
      id: 4,
      title: 'السلطات - Salad',
      image: '../../../img/list/salat.png',
      selected: false,
      items: [
          {
              id: 1,
              title: 'سلطه سيزر ساده',
              description: 'Plain Caesar salad - خس طازج مع جبنة بارميزان وقطع الخبز المحمص تخلط مع صلصة السيزر',
              image: '../../img/food/img-1(2).jpg',
              price: "25.10",
              calories: 0,
              available: true,
              selected: false,
          },
          {
              id: 2,
              title: 'سلطه سيزر بالدجاج',
              description: 'Caesar salad with chicken - خس طازج مع جبنة البارميزان وقطع الخبز المحمص تخلط مع صلصة السيزر مع صدر دجاج مشوي',
              image: '../../img/food/img-2(3).jpg',
              price: "35.10",
              calories: 0,
              available: true,
              selected: false,
          },
          {
              id: 3,
              title: 'سلطه سيزر بالروبيان',
              description: 'Caesar salad with shrimp - خس طازج مع جبنة بارميزان وقطع الخبز المحمص تخلط مع صلصة السيزر مع الروبيان المشوي',
              image: '../../img/food/img-3(3).jpg',
              price: "39.10",
              calories: 0,
              available: true,
              selected: false,
          },
          {
              id: 4,
              title: 'سلطه البورتا',
              description: 'Burrata salad - خس - جرجير - سبانخ - جبنة ايطالي براتا - زيتون الرمان -  طماطم مجففه - مغطاة بصلصة البلسمك',
              image: '../../img/food/img-4(3).jpg',
              price: "39.10",
              calories: 0,
              available: true,
              selected: false,
          },
          {
              id: 5,
              title: 'سلطة دبليو فايف',
              description: 'Salad w5',
              image: '../../img/food/img-6(2).jpg',
              price: "39.10",
              calories: 0,
              available: true,
              selected: false,
          },
          {
              id: 6,
              title: 'سلطه اليبتروت',
              description: 'Aliptrot Salad',
              image: '../../img/food/-08.jpg',
              price: "29.10",
              calories: 0,
              available: true,
              selected: false,
          },
      ]
  },
  {
      id: 5,
      title: 'المكرونة - Pasta',
      image: '../../../img/list/pasta.png',
      selected: false,
      items: [
          {
              id: 1,
              title: 'باستا سلمون',
              description: 'Salmon pasta - معكرونه فيتوتشيني بالسلمون المشوي والثوم مع كريمة وجبن البارميزان',
              image: '../../img/food/img-1(3).jpg',
              price: "54.10",
              calories: 0,
              available: true,
              selected: false,
          },
          {
              id: 2,
              title: 'باستا ارابياتا',
              description: 'Arrabbiata pasta - معكرونة مع صلصة الطماطم الحارة وجبن البارميزان تقدم ساده',
              image: '../../img/food/img-2(4).jpg',
              price: "33.10",
              calories: 0,
              available: true,
              selected: false,
          },
          {
              id: 3,
              title: 'باستا روبيان',
              description: 'Shrimp pasta - معكرونة بيني بالدجاج او الروبيان مطهيه بتوابل الكيجن مع الكريمة وجبن البارميزان',
              image: '../../img/food/img-3(4).jpg',
              price: "45.10",
              calories: 0,
              available: true,
              selected: false,
          },
          {
              id: 4,
              title: 'باستا الدجاج والثوم',
              description: 'Chicken and garlic pasta - معكرونة فيتوتشيني بالدجاج المشوي والثوم مع كريمة وجبن البارميزان',
              image: '../../img/food/img-4(4).jpg',
              price: "48.10",
              calories: 0,
              available: true,
              selected: false,
          },
          {
              id: 5,
              title: 'تشيلي شرمب باستا',
              description: 'Chili Shrimp Pasta',
              image: '../../img/food/img-5(3).jpg',
              price: "55.10",
              calories: 0,
              available: true,
              selected: false,
          },
          {
              id: 6,
              title: 'تورتليني',
              description: 'Tortellini - مكرونة محشوه جبنة وسبانخ تقدم مع صوص الروزيتو',
              image: '../../img/food/img-6(3).jpg',
              price: "42.10",
              calories: 0,
              available: true,
              selected: false,
          },
          {
              id: 7,
              title: 'باستا ارابياتا الدجاج',
              description: 'Chicken arrabbiata pasta - معكرونة مع صلصة الطمام الحارة وجبن البارميزان تقدم سادة او مع اختياركم من الدجاج',
              image: '../../img/food/img-7(1).jpg',
              price: "44.10",
              calories: 0,
              available: true,
              selected: false,
          },
          {
              id: 8,
              title: 'باستا سيفود',
              description: 'Seafood pasta',
              image: '../../img/food/img-8(1).jpg',
              price: 60,
              calories: 0,
              available: true,
              selected: false,
          },
          {
              id: 9,
              title: 'باستا ارابياتا سلمون',
              description: 'Arrabbiata salmon pasta - معكرونة مع صلصة الطمام الحارة وجبن البارميزان تقدم سادة او مع اختياركم من السالمون',
              image: '../../img/food/img-9(1).jpg',
              price: "54.10",
              calories: 0,
              available: true,
              selected: false,
          },
          {
              id: 10,
              title: 'لازانيا',
              description: 'Lasagna - طبقات من المكرونه - صوص اللحم - جبن الموتزريلا - جبن البارميزان وصوص الباشميل',
              image: '../../img/food/img-10(1).jpg',
              price: "44.50",
              calories: 0,
              available: true,
              selected: false,
          },
      ]
  },
  {
      id: 6,
      title: 'البيتزا - Pizza',
      image: '../../../img/list/pizza.png',
      selected: false,
      items: [
          {
              id: 1,
              title: 'بيتزا الباربكيو',
              description: 'BBQ pizza - مع الدجاج وصوص الباربكيو - الفلفل - الزيتون - مع جبنة الموتزريلا الايطاليه',
              image: '../../img/food/1.jpg',
              price: "38.10",
              calories: 0,
              available: true,
              selected: false,
          },
          {
              id: 2,
              title: 'بيتزا بافلو',
              description: 'Buffalo pizza - صوص البيتزا اللذيذ وجبنه الموتزريلا وجبنة البافلو الايطاليه',
              image: '../../img/food/2.jpg',
              price: "38.10",
              calories: 0,
              available: true,
              selected: false,
          },
          {
              id: 3,
              title: 'بيتزا بيستو سي فود',
              description: 'Pesto Seafood Pizza',
              image: '../../img/food/3.jpg',
              price: "44.10",
              calories: 0,
              available: true,
              selected: false,
          },
          {
              id: 4,
              title: 'بيتزا بيستو تشيكن',
              description: 'Pesto Chicken Pizza -  صوص البيستو اللذيذ مع الدجاج وبروكلي - جبنة الموتزريلا الايطالية',
              image: '../../img/food/4.jpg',
              price: "38.10",
              calories: 0,
              available: true,
              selected: false,
          },
          {
              id: 5,
              title: 'بيتزا عشاق اللحوم',
              description: 'Meat lovers pizza - صوص البيتزا اللذيذ مع البيبروني - كرات اللحم - مع جبنة الموتزريلا الايطالية',
              image: '../../img/food/5(1).jpg',
              price: "38.10",
              calories: 0,
              available: true,
              selected: false,
          },
      ]
  },
  {
      id: 7,
      title: 'الاطباق الرئيسية - Main courses',
      image: '../../../img/list/dish.png',
      selected: false,
      items: [
          {
              id: 1,
              title: 'لحم استراجانوف',
              description: 'Beef stroganoff - فيلية اللحم البقري مع صوص الديمي جلاس الكريمه الغنيه- تقدم مع اختياركم من طبقين جانبيين',
              image: '../../img/food/1(1).jpg',
              price: "89.10",
              calories: 0,
              available: true,
              selected: false,
          },
          {
              id: 2,
              title: 'دجاج استراجانوف',
              description: 'Chicken Stroganoff - فيلية الدجاج البقري مع صوص الديمي جلاس الكريمه الغنيه- تقدم مع اختياركم من طبقين جانبيين',
              image: '../../img/food/2(1).jpg',
              price: "44.10",
              calories: 0,
              available: true,
              selected: false,
          },
          {
              id: 3,
              title: 'روزماري استيك ريب اي',
              description: 'Rosemary Steak Rib Eye - استيك مشوي مع صوص المشروم - تقدم مع اختياركم من طبقين جانبيين',
              image: '../../img/food/3(1).jpg',
              price: "108.10",
              calories: 0,
              available: true,
              selected: false,
          },
          {
              id: 4,
              title: 'روزماري سلمون',
              description: 'Rosemary Salmon - فيليه السالمون المشوي المتبل بصوص الليمون مع كريمه - يقدم مع طبقين جانبيين من اختياركم',
              image: '../../img/food/4(1).jpg',
              price: "79.10",
              calories: 0,
              available: true,
              selected: false,
          },
          {
              id: 5,
              title: 'بيكاتا ميداليون',
              description: 'Piccata medallion',
              image: '../../img/food/5(2).jpg',
              price: "100.10",
              calories: 0,
              available: true,
              selected: false,
          },
          {
              id: 6,
              title: 'اسبيتادا دجاج',
              description: 'Espetada chicken - دجاج وخضار مشوي ومتبل بتوابل الكاجن اللذيذة - تقدم مع طبقين جانبيين من اختياركم',
              image: '../../img/food/6.jpg',
              price: "48.10",
              calories: 0,
              available: true,
              selected: false,
          },
          {
              id: 7,
              title: 'دجاج الكيجن',
              description: 'Cajun chicken - صدور الدجاج المشوي والمتبلة ومغطاه بصوص الكيجن يقدم مع طبقين جانبيين من اختياركم',
              image: '../../img/food/7.jpg',
              price: "44.10",
              calories: 0,
              available: true,
              selected: false,
          },
          {
              id: 8,
              title: 'موزه غنم',
              description: 'Sheep banana - متبلة بتوابل شهية وصوص الديمي جلاس تقدم مع طبقين جانبيين من اختياركم',
              image: '../../img/food/8.jpg',
              price: 115,
              calories: 0,
              available: true,
              selected: false,
          },
          {
              id: 9,
              title: 'روزماري دجاج',
              description: 'Rosemary chicken',
              image: '../../img/food/9.jpg',
              price: "44.10",
              calories: 0,
              available: true,
              selected: false,
          },
          {
              id: 10,
              title: 'سلمون بوبيت',
              description: 'Salmon bobette - شرائح السالمون المحشيه مع الروبيان وكريمة السبانخ اللديدة وتقدم مع صوص الثلاث جبن  - تقدم مع طبقين جانبيين من اختياركم',
              image: '../../img/food/10.jpg',
              price: "80.50",
              calories: 0,
              available: true,
              selected: false,
          },
          {
              id: 11,
              title: 'اسبيتادا لحم',
              description: 'Espetada meat - لحم وخضار مشوي ومتبل بتوابل الكاجن اللذيذة - يقدم مع طبقين جانبيين من اختياركم',
              image: '../../img/food/11.jpg',
              price: "100.10",
              calories: 0,
              available: true,
              selected: false,
          },
          {
              id: 12,
              title: 'اسبيتادا روبيان',
              description: 'Espetada shrimp - روبيان مغطى باللحم المقدد مشوي ومتبل بتوابل الكاجن اللذيذة - يقدم مع طبقين جانبيين من اختياركم',
              image: '../../img/food/13.jpg',
              price: "71.10",
              calories: 0,
              available: true,
              selected: false,
          },
          {
              id: 13,
              title: 'دجاج بالاعشاب',
              description: 'Herbal chicken - صدور دجاج مشوي بالاعشاب - يقدم مع طبقين جانبيين من اختياركم',
              image: '../../img/food/6c.jpg',
              price: "44.10",
              calories: 0,
              available: true,
              selected: false,
          },
          {
              id: 14,
              title: 'دجاج رول',
              description: 'Chicken roll',
              image: '../../img/food/07d5.jpg',
              price: "55.10",
              calories: 0,
              available: true,
              selected: false,
          },
      ]
  },
  {
      id: 8,
      title: 'السندوتشات - Sandwiches',
      image: '../../../img/list/sandwich.png',
      selected: false,
      items: [
          {
              id: 1,
              title: 'سلايدر برجر',
              description: 'Burger slider - لحم البرجر اللذيذ يقدم في ثلاث نكهات مختلفة مع الجبن وصوص الرانش',
              image: '../../img/food/1(2).jpg',
              price: "40.50",
              calories: 0,
              available: true,
              selected: false,
          },
          {
              id: 2,
              title: 'برجر دبليو فايف',
              description: 'w5 burger - اللحم البريسكت المتبل بتوابل دبليو فايف مع اللحم المقدد - الجبن - خس - طماطم -بصل ومخلل',
              image: '../../img/food/2(2).jpg',
              price: "39.50",
              calories: 0,
              available: true,
              selected: false,
          },
          {
              id: 3,
              title: 'تشيز برجر لحم',
              description: 'Beef cheese burger - لحم البرجر اللذيذ مع الجبن السويسري - الفطر - خس - طماطم - شرائح البصل',
              image: '../../img/food/3(2).jpg',
              price: "34.50",
              calories: 0,
              available: true,
              selected: false,
          },
          {
              id: 4,
              title: 'كرسبي برجر',
              description: 'Crispy burger - صدر الدجاج المقلي مع سلطه الديناميت وصوص التكساس مع الخس - طماطم - مخلل',
              image: '../../img/food/4(2).jpg',
              price: "39.10",
              calories: 0,
              available: true,
              selected: false,
          },
          {
              id: 5,
              title: 'باربكيو برجر',
              description: 'BBQ Burger - دجاج الباربكيو المشوي مع الجبن - خس - طماطم - شرائح البصل - مخلل',
              image: '../../img/food/5(3).jpg',
              price: "39.10",
              calories: 0,
              available: true,
              selected: false,
          },
          {
              id: 6,
              title: 'كاجن كريم برجر',
              description: 'Cajun Cream Burger - دجاج الكاجن المشوي مع الجبن - خس - طماطم - شرائح البصل والمخلل',
              image: '../../img/food/7(1).jpg',
              price: "39.10",
              calories: 0,
              available: true,
              selected: false,
          },
          {
              id: 7,
              title: 'تشيز برجر الدجاج',
              description: 'Chicken cheese burger - برجر الدجاج اللذيذ مع الجبن  - خس - طماطم - شرائح البصل والمخلل',
              image: '../../img/food/8(1).jpg',
              price: "33.10",
              calories: 0,
              available: true,
              selected: false,
          },
          {
              id: 8,
              title: 'سويس ماشروم برجر',
              description: 'Swiss Mushroom Burger',
              image: '../../img/food/9(1).jpg',
              price: "39.10",
              calories: 0,
              available: true,
              selected: false,
          },
          {
              id: 9,
              title: 'ترافل ماشروم برجر',
              description: 'Truffle Mushroom Burger - لحم البرجر اللذيذ مع الجبن السويسري - الفطر - صوص الترافل',
              image: '../../img/food/10(1).jpg',
              price: "39.10",
              calories: 0,
              available: true,
              selected: false,
          },
      ]
  },
  {
      id: 9,
      title: 'الحلا - Sweets',
      image: '../../../img/list/soit.png',
      selected: false,
      items: [
          {
              id: 1,
              title: 'شوكولاته فوندون',
              description: 'Chocolate fondue - كيكة الشوكولاته السائلة اللذيذة مع الايس كريم',
              image: '../../img/food/136.jpg',
              price: "29.10",
              calories: 0,
              available: true,
              selected: false,
          },
          {
            id: 2,
            title: 'فرنش توست',
            description: 'French Toast - فرنش توست يقدم مع صوص النوتيلا و البستاشيو و الكراميل',
            image: '../../img/food/toast.jpg',
            price: "29",
            calories: 0,
            available: true,
            selected: false,
        },
          {
              id: 3,
              title: 'ميني بان كيك',
              description: 'Mini pancake',
              image: '../../img/food/137.jpg',
              price: "23.10",
              calories: 0,
              available: true,
              selected: false,
          },
          {
              id: 4,
              title: 'فولكانو كيك',
              description: 'Volcano Cake - كيك الشوكولاتة الذائبة تقدم مع الايس كريم الفانيليا',
              image: '../../img/food/140.jpg',
              price: "25.10",
              calories: 0,
              available: true,
              selected: false,
          },
          {
              id: 5,
              title: 'مولتن شوكولاته',
              description: 'Molten chocolate',
              image: '../../img/food/139.jpg',
              price: "29.10",
              calories: 0,
              available: true,
              selected: false,
          },
          {
              id: 6,
              title: 'تشيز كيك ميكس بيري',
              description: 'Mixed Berry Cheesecake - تشيز كيك ميكس بيري محشوة بالتوت الأزرق و التوت الأحمر مع كريمة الجبنة والفواكه',
              image: '../../img/food/berry.jpg',
              price: "25",
              calories: 0,
              available: true,
              selected: false,
          },
          {
            id: 7,
            title: 'كيكة الكوكيز',
            description: 'Cookie Cake - كيكة الكوكيز محشوة بالمارشملو و حبات الشوكولاته السوداء مع النوتيلا و الآيس كريم تقدم حارة',
            image: '../../img/food/cooki.jpg',
            price: "25",
            calories: 0,
            available: true,
            selected: false,
        },
          {
              id: 8,
              title:'كيكة التمر',
              description: 'Date Cake - كيكة التمر مع صوص الكراميل و الآيس كريم تقدم حارة',
              image: '../../img/food/date.jpg',
              price: "17",
              calories: 0,
              available: true,
              selected: false,
          },
          {
              id: 9,
              title: 'كيكة البستاشيو',
              description: 'Pistachio Cake - كيكة البستاشيو محشوة بالفستق و الكريمة مع الفراولة',
              image: '../../img/food/pista.jpg',
              price: "28",
              calories: 0,
              available: true,
              selected: false,
          },
          {
              id: 10,
              title: 'موس تشوكلت',
              description: 'Chocolate Mousse - موس تشوكلت بحشوة التوت الأزرق مع حبة الفراولة',
              image: '../../img/food/mouse.jpg',
              price: "25",
              calories: 0,
              available: true,
              selected: false,
          },
          {
            id: 11,
            title: 'كيكة البراونيز',
            description: 'Brownie Cake - كيكة البراونيز مع المارشملو و مزيج من الشوكولاته السوداء و النوتيلا مع الآيس كريم تقدم حارة',
            image: '../../img/food/brwn.jpg',
            price: "29",
            calories: 0,
            available: true,
            selected: false,
        },
      ]
  },
  {
      id: 10,
      title: 'المشروبات الساخنة - Hot drinks',
      image: '../../../img/list/coffe-cup.png',
      selected: false,
      items: [
          {
              id: 1,
              title: 'لاتيه سولتد كراميل',
              description: 'Salted caramel latte',
              image: '../../img/food/17t.jpg',
              price: "19.10",
              calories: 0,
              available: true,
              selected: false,
          },
          {
              id: 2,
              title: 'موكا حار',
              description: 'Hot mocha',
              image: '../../img/food/18t.jpg',
              price: "19.10",
              calories: 0,
              available: true,
              selected: false,
          },
          {
              id: 3,
              title: 'كورتادو',
              description: 'Cortado',
              image: '../../img/food/19t.jpg',
              price: "15.10",
              calories: 0,
              available: true,
              selected: false,
          },
          {
              id: 4,
              title: 'كيميكس',
              description: 'Chemex',
              image: '../../img/food/20t.jpg',
              price: "15.10",
              calories: 0,
              available: true,
              selected: false,
          },
          {
              id: 5,
              title: 'كوردمون لاتيه',
              description: 'Cordmond latte',
              image: '../../img/food/21t.jpg',
              price: "20.10",
              calories: 0,
              available: true,
              selected: false,
          },
          {
              id: 6,
              title: 'كابتشينو',
              description: 'cappuccino',
              image: '../../img/food/22t.jpg',
              price: "18.10",
              calories: 0,
              available: true,
              selected: false,
          },
          {
              id: 7,
              title: 'اسبريسو',
              description: 'Espresso',
              image: '../../img/food/23t.jpg',
              price: "12.10",
              calories: 0,
              available: true,
              selected: false,
          },
          {
              id: 8,
              title: 'امريكانو حار',
              description: 'Hot americano',
              image: '../../img/food/24t.jpg',
              price: "12.10",
              calories: 0,
              available: true,
              selected: false,
          },
          {
              id: 9,
              title: 'هوت شوكلت',
              description: 'hot chocolate',
              image: '../../img/food/1t.jpg',
              price: "14.10",
              calories: 0,
              available: true,
              selected: false,
          },
          {
              id: 10,
              title: 'حار v60',
              description: 'Hot v60',
              image: '../../img/food/2t.jpg',
              price: "14.10",
              calories: 0,
              available: true,
              selected: false,
          },
          {
              id: 11,
              title: 'لاتيه',
              description: 'Latte',
              image: '../../img/food/3t.jpg',
              price: "18.10",
              calories: 0,
              available: true,
              selected: false,
          },
          {
              id: 12,
              title: 'سبانش لاتيه',
              description: 'Spanish latte',
              image: '../../img/food/4t.jpg',
              price: "20.10",
              calories: 0,
              available: true,
              selected: false,
          },
          {
              id: 13,
              title: 'فلات وايت',
              description: 'Flat white',
              image: '../../img/food/5t.jpg',
              price: "18.10",
              calories: 0,
              available: true,
              selected: false,
          },
          {
              id: 14,
              title: 'قهوه عربي',
              description: 'Arabic coffee',
              image: '../../img/food/6t.jpg',
              price: "32.60",
              calories: 0,
              available: true,
              selected: false,
          },
      ]
  },
  {
      id: 10,
      title: 'المشروبات الباردة - Cold drinks',
      image: '../../../img/list/bard.png',
      selected: false,
      items: [
          {
              id: 1,
              title: 'ايس كوردمون لاتيه',
              description: 'Ice cordamon latte',
              image: '../../img/food/7t.jpg',
              price: 23,
              calories: 0,
              available: true,
              selected: false,
          },
          {
              id: 2,
              title: 'ايس بستاشيو',
              description: 'Ice pistachio',
              image: '../../img/food/9t.jpg',
              price: "27.10",
              calories: 0,
              available: true,
              selected: false,
          },
          {
              id: 3,
              title: 'ايس سولتد كراميل',
              description: 'Ice salted caramel',
              image: '../../img/food/8t.jpg',
              price: "19.10",
              calories: 0,
              available: true,
              selected: false,
          },
          {
              id: 4,
              title: 'فرابيه موكا',
              description: 'Mocha frappe',
              image: '../../img/food/10t.jpg',
              price: "22.10",
              calories: 0,
              available: true,
              selected: false,
          },
          {
              id: 5,
              title: 'ايس سبانش',
              description: 'Ice Spanish',
              image: '../../img/food/11t.jpg',
              price: "18.10",
              calories: 0,
              available: true,
              selected: false,
          },
          {
              id: 6,
              title: 'ايس لاتيه',
              description: 'Ice latte',
              image: '../../img/food/12t.jpg',
              price: "17.10",
              calories: 0,
              available: true,
              selected: false,
          },
          {
              id: 7,
              title: 'ايس امريكانو',
              description: 'Ice Americano',
              image: '../../img/food/13t.jpg',
              price: "15.10",
              calories: 0,
              available: true,
              selected: false,
          },
          {
              id: 8,
              title: 'ايس v60',
              description: 'Ace v60',
              image: '../../img/food/14t.jpg',
              price: "14.10",
              calories: 0,
              available: true,
              selected: false,
          },
          {
              id: 9,
              title: 'ايس وايت موكا',
              description: 'Ice white mocha',
              image: '../../img/food/15t.jpg',
              price: "19.10",
              calories: 0,
              available: true,
              selected: false,
          },
          {
              id: 10,
              title: 'فرابيه كراميل',
              description: 'Caramel frappe',
              image: '../../img/food/16t.jpg',
              price: "23.10",
              calories: 0,
              available: true,
              selected: false,
          },
      ]
  },
  {
      id: 10,
      title: 'العصائر والموهيتو - Juices and mojitos',
      image: '../../../img/list/moheto5a9.png',
      selected: false,
      items: [
          {
              id: 1,
              title: 'دبليو فايف كوكتيل',
              description: 'w5 cocktail',
              image: '../../img/food/2s.jpg',
              price: "20.10",
              calories: 0,
              available: true,
              selected: false,
          },
          {
              id: 2,
              title: 'عصير الليمون والنعناع',
              description: 'Lemon and mint juice',
              image: '../../img/food/4s.jpg',
              price: "18.10",
              calories: 0,
              available: true,
              selected: false,
          },
          {
              id: 3,
              title: 'عصير برتقال',
              description: 'Orange juice',
              image: '../../img/food/8s.jpg',
              price: "17.40",
              calories: 0,
              available: true,
              selected: false,
          },
          {
              id: 4,
              title: 'عصير افوكادو',
              description: 'avocado juice',
              image: '../../img/food/9s.jpg',
              price: "20.10",
              calories: 0,
              available: true,
              selected: false,
          },
          {
              id: 5,
              title: 'عصير التفاح والموز',
              description: 'Apple and banana juice',
              image: '../../img/food/10s.jpg',
              price: "18.10",
              calories: 0,
              available: true,
              selected: false,
          },
          {
              id: 6,
              title: ' دبليو فايف موهيتو',
              description: 'w5 mojito',
              image: '../../img/food/1s.jpg',
              price: "20.10",
              calories: 0,
              available: true,
              selected: false,
          },
          {
              id: 7,
              title: 'فيرجن موهيتو',
              description: 'Virgin Mojito',
              image: '../../img/food/3s.jpg',
              price: "20.10",
              calories: 0,
              available: true,
              selected: false,
          },
          {
              id: 8,
              title: 'موهيتو فراوله',
              description: 'Strawberry mojito',
              image: '../../img/food/5s.jpg',
              price: "20.10",
              calories: 0,
              available: true,
              selected: false,
          },
          {
              id: 9,
              title: 'موهيتو التوت الازرق',
              description: 'Blueberry mojito',
              image: '../../img/food/6s.jpg',
              price: "18.10",
              calories: 0,
              available: true,
              selected: false,
          },
          {
              id: 10,
              title: 'موهيتو خوخ',
              description: 'Peach mojito',
              image: '../../img/food/7s.jpg',
              price: "20.10",
              calories: 0,
              available: true,
              selected: false,
          },
          {
              id: 11,
              title: 'موهيتو التوت المشكل',
              description: 'Mixed berry mojito',
              image: '../../img/food/11s.jpg',
              price: "19.10",
              calories: 0,
              available: true,
              selected: false,
          },
      ]
  },
  {
      id: 11,
      title: 'الشيشة الصباحية - Shisha AM',
      image: '../../../img/list/shisha.png',
      selected: false,
      items: [
          {
              id: 1,
              title: 'شيشه كوكتيل دبليو فايف',
              description: 'w5 shisha cocktail',
              image: '../../img/food/shisha2.png',
              price: 45,
              calories: 0,
              available: true,
              selected: false,
          },
          {
              id: 2,
              title: 'شيشه فاخر',
              description: 'Luxurious shisha',
              image: '../../img/food/shisha2.png',
              price: 45,
              calories: 0,
              available: true,
              selected: false,
          },
          {
              id: 3,
              title: 'شيشه نخله',
              description: 'Shisha palm',
              image: '../../img/food/shisha2.png',
              price: 45,
              calories: 0,
              available: true,
              selected: false,
          },
          {
              id: 4,
              title: 'شيشه عنب توت',
              description: 'Grape berry shisha',
              image: '../../img/food/shisha2.png',
              price: 45,
              calories: 0,
              available: true,
              selected: false,
          },
          {
              id: 5,
              title: 'شيشه مكس',
              description: 'Shisha mix',
              image: '../../img/food/shisha2.png',
              price: 45,
              calories: 0,
              available: true,
              selected: false,
          },
          {
              id: 6,
              title: 'شيشه عنب نعناع',
              description: 'Grape mint shisha',
              image: '../../img/food/shisha2.png',
              price: 45,
              calories: 0,
              available: true,
              selected: false,
          },
          {
              id: 7,
              title: 'شيشه بلوبيري',
              description: 'Blueberry shisha',
              image: '../../img/food/shisha2.png',
              price: 45,
              calories: 0,
              available: true,
              selected: false,
          },
          {
              id: 8,
              title: 'شيشه خوخ',
              description: 'Peach shisha',
              image: '../../img/food/shisha2.png',
              price: 45,
              calories: 0,
              available: true,
              selected: false,
          },
          {
              id: 9,
              title: 'شيشه بطيخ',
              description: 'Watermelon shisha',
              image: '../../img/food/shisha2.png',
              price: 45,
              calories: 0,
              available: true,
              selected: false,
          },
      ]
  },
  {
      id: 12,
      title: 'الشيشة المسائية - Shisha PM',
      image: '../../../img/list/shisha.png',
      selected: false,
      items: [
          {
              id: 1,
              title: 'شيشه كوكتيل دبليو فايف',
              description: 'w5 shisha cocktail',
              image: '../../img/food/shisha2.png',
              price: 50,
              calories: 0,
              available: true,
              selected: false,
          },
          {
              id: 2,
              title: 'شيشه فاخر',
              description: 'Luxurious shisha',
              image: '../../img/food/shisha2.png',
              price: 50,
              calories: 0,
              available: true,
              selected: false,
          },
          {
              id: 3,
              title: 'شيشه نخله',
              description: 'Shisha palm',
              image: '../../img/food/shisha2.png',
              price: 50,
              calories: 0,
              available: true,
              selected: false,
          },
          {
              id: 4,
              title: 'شيشه عنب توت',
              description: 'Grape berry shisha',
              image: '../../img/food/shisha2.png',
              price: 50,
              calories: 0,
              available: true,
              selected: false,
          },
          {
              id: 5,
              title: 'شيشه مكس',
              description: 'Shisha mix',
              image: '../../img/food/shisha2.png',
              price: 50,
              calories: 0,
              available: true,
              selected: false,
          },
          {
              id: 6,
              title: 'شيشه عنب نعناع',
              description: 'Grape mint shisha',
              image: '../../img/food/shisha2.png',
              price: 50,
              calories: 0,
              available: true,
              selected: false,
          },
          {
              id: 7,
              title: 'شيشه بلوبيري',
              description: 'Blueberry shisha',
              image: '../../img/food/shisha2.png',
              price: 50,
              calories: 0,
              available: true,
              selected: false,
          },
          {
              id: 8,
              title: 'شيشه خوخ',
              description: 'Peach shisha',
              image: '../../img/food/shisha2.png',
              price: 50,
              calories: 0,
              available: true,
              selected: false,
          },
          {
              id: 9,
              title: 'شيشه بطيخ',
              description: 'Watermelon shisha',
              image: '../../img/food/shisha2.png',
              price: 50,
              calories: 0,
              available: true,
              selected: false,
          },
      ]
  },
  ]