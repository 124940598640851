import { Swiper, SwiperSlide } from 'swiper/react'
import { Pagination } from 'swiper'
import { useEffect, useState } from 'react'
import { data as categories } from '../data'
import Item from './Item'
import CardLoader from './CardLoader'

import 'swiper/css'
import 'swiper/css/pagination'

const Items = (props) => {
  const [isLoading, setIsLoading] = useState(true);
  const {
    pagination,
    close,
    isSubmitted,
    filteredItems,
    setSwiper,
    setModalOn,
    modalOn,
    singleItem,
    setSingleItem,
    cartOn,
  } = props

  useEffect(() => {
    document.body.style.overflow = cartOn ? 'hidden' : 'visible'
  }, [cartOn])

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 700);
    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      <Swiper
        loop={true}
        autoHeight={true}
        pagination={pagination}
        speed={500}
        modules={[Pagination]}
        onSwiper={(swiper) => setSwiper(swiper)}
       
        onSlideChange={() => {
          const activeCat = document.querySelector(
            '.swiper-pagination-bullet-active'
          )
          document.documentElement.scrollTop = 0
          activeCat?.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
            inline: 'center',
          })
          close()
        }}
      >
        {categories.map((category, i) => (
          <SwiperSlide key={i}>
            <div className='relative space-y-2 flex flex-col justify-center overflow-hidden items-center animateItems'>
              <div className='w-full p-4 flex flex-col gap-2 items-center bg-white dark:bg-gray-700'>
                <div className={`w-full flex flex-col gap-2 absolute inset-0 ${isLoading ? 'opacity-100 relative' : 'opacity-0'}`}>
                  {Array.from({ length: category.items?.length || 0 }).map((_, i) => (
                    <CardLoader key={i} />
                  ))}
                </div>
                <div className={`w-full ${isLoading ? 'opacity-0 absolute' : 'opacity-100 relative'}`}>
                  {isSubmitted ? (
                    filteredItems.length === 0 ? (
                      <div className='w-full flex items-center font-semibold gap-4 justify-center flex-col my-5 dark:text-white'>
                        <img
                          src='../../img/delete.png'
                          alt='not-found'
                          width='100em'
                          height='100em'
                        />
                        <h2 className='text-3xl text-center text-[#432918] dark:text-white'>
                          لا يوجد نتائج
                        </h2>
                      </div>
                    ) : (
                      <div className="w-full flex flex-col gap-2">
                        {filteredItems.map((item, index) => (
                          <Item
                            item={item}
                            key={index}
                            modalOn={modalOn}
                            setModalOn={setModalOn}
                            singleItem={singleItem}
                            setSingleItem={setSingleItem}
                          />
                        ))}
                      </div>
                    )
                  ) : (
                    <div className="w-full flex flex-col gap-2">
                      {category.items?.map((item, index) => (
                        <Item
                          item={item}
                          key={index}
                          modalOn={modalOn}
                          setModalOn={setModalOn}
                          singleItem={singleItem}
                          setSingleItem={setSingleItem}
                        />
                      ))}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </>
  )
}

export default Items
